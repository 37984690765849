/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import * as React from "react"

import { ChakraProvider } from "@chakra-ui/react"
import theme from "./src/@chakra-ui/gatsby-plugin/theme"
import Fonts from "./src/components/Fonts"

export const wrapRootElement = ({ element }) => (
  // <ChakraProvider cssVarsRoot="body" theme={theme}>
  <ChakraProvider theme={theme}>
    <Fonts />
    {element}
  </ChakraProvider>
)

// Scoll to top issue with nav and hero on same page (for some reason).
// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const { pathname } = location
//   // list of routes for the scroll-to-top-hook
//   const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     window.scrollTo(0, 0)
//   }

//   return false
// }
