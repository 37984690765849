import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

import font from "./font"

const common = {
  background: "secondary.normal",
  // background:
  bgGradient:
    "linear(secondary.light 0%, secondary.normal 65%, secondary.dark 100%)",
  transition: "background 0.3s ease; color 0.3s ease",
  color: "gray.50",
  fontFamily: font.heading,
  fontWeight: "normal",
  letterSpacing: 1.1,

  _hover: {
    background: "gray.300",
    bgGradient:
      "linear(secondary.dark 0%, secondary.normal 35%, secondary.light 100%)",
  },
}

const cta = defineStyle({ ...common, fontSize: "2xl", px: 4, py: 6 })
const cta_large = defineStyle({
  ...common,
  fontSize: "3xl",
  px: 5,
  py: 7,
})

export default defineStyleConfig({
  variants: { cta, cta_large },
})
