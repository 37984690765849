import { extendTheme } from "@chakra-ui/react"

import Text from "./text"
import Heading from "./heading"
import Button from "./button"
import Link from "./link"

import colors from "./colour"
import fonts from "./font"

const theme = extendTheme({
  fonts,
  colors,

  components: {
    Heading,
    Text,
    Button,
    Link,
  },
  styles: {
    global: {
      body: {
        color: "white",
        fontSize: 20,
        bg: "primary.darker",
      },
    },
  },
})

export default extendTheme(theme)
