import { defineStyleConfig } from "@chakra-ui/react"

export default defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    lineHeight: 1.3,
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    spaced: {
      py: { base: 4, sm: 4, md: 4, lg: 4 },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
})
