import { Global } from "@emotion/react"
import * as React from "react"

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Trebuchet MS';
        src: url('/fonts/Trebuchet_MS_V1.woff2') format('woff2'),
        url('/fonts/Trebuchet_MS_V1.woff') format('woff'),
        url('/fonts/TrebuchetMS.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Trebuchet MS';
        src: url('/fonts/Trebuchet_MS_Bold_V1.woff2') format('woff2'),
        url('/fonts/Trebuchet_MS_Bold_V1.woff') format('woff'),
        url('/fonts/TrebuchetMS-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Trebuchet MS';
        src: url('/fonts/Trebuchet_MS_Italic_V1.woff2') format('woff2'),
        url('/fonts/Trebuchet_MS_Italic_V1.woff') format('woff'),
        url('/fonts/TrebuchetMS-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Trebuchet MS';
        src: url('/fonts/Trebuchet_MS_Bold_Italic_V1.woff2') format('woff2'),
        url('/fonts/Trebuchet_MS_Bold_Italic_V1.woff') format('woff'),
        url('/fonts/Trebuchet-BoldItalic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
    } 
    
    @font-face {
        font-family: 'Impact';
        src: url('/fonts/Impact.woff2') format('woff2'),
            url('/fonts/Impact.woff') format('woff'),
            url('/fonts/Impact.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
       
      `}
  />
)

export default Fonts
