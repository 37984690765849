import { defineStyle, defineStyleConfig } from "@chakra-ui/react"
import customColours from "./colour"

import font from "./font"

const cta = defineStyle({
  //   transition: "background 1s ease; color 1s ease",
  textColor: "white",

  _hover: {
    textDecoration: "none",
  },
})

const bold = defineStyle({
  //   transition: "background 1s ease; color 1s ease",
  textColor: "white",

  _hover: {
    // fontWeight: "bold",
    letterSpacing: 0.3,
    transition: "all 0.1s ease",
    textDecoration: "none",
  },
})

export default defineStyleConfig({
  baseStyle: {
    textColor: "highlight",
  },

  variants: { cta, bold },
})
