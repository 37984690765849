export default {
  primary: {
    normal: "#417E87",
    dark: "#396E76",
    darker: "#0d2a2e",
    lighter: "#6AA5AF",
    light: "#5C939C",
  },
  secondary: {
    lighter: "#ED2C2F",
    light: "#E12023",
    normal: "#D51317",
    dark: "#A40E11",
    // darker: "#A40E11",
  },
  // highlight: "#e7ab75",
  // highlight: "#000",
  highlight: "#cc8f45",
}
