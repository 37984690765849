import { defineStyleConfig, defineStyle } from "@chakra-ui/react"

const variants = defineStyle({
  h1: {
    py: 4,
    fontSize: { base: "5xl", sm: "6xl" },

    letterSpacing: 1.1,
    fontWeight: "normal",
  },
  h2: {
    letterSpacing: 1.3,
    pt: 6,
    pb: 4,
    fontSize: "3xl",
    color: "highlight",
    fontWeight: "normal",
  },
  h3: {
    py: 4,
    fontSize: "2xl",
    fontWeight: "normal",
  },
  h4: {
    py: 4,
    fontSize: "xl",
    fontWeight: "normal",
  },
})

export default defineStyleConfig({
  // Styles for the base style
  baseStyle: {
    letterSpacing: 0.6,
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants,
  // The default `size` or `variant` values
  defaultProps: {},
})
